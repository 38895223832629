import React, { useContext, useState } from "react"
import axios from 'axios'
import Layout from "../components/layout"
import SEO from "../components/seo"
import { LangContext } from "../context/langContext"
import { TextContext } from "../context/textContext"
import arrowDown from '../images/home/arrowDown.svg'
import { FormattedMessage } from "react-intl";
import Loader from '../components/loader'
import { store } from 'react-notifications-component';
import 'react-notifications-component/dist/theme.css';
import 'animate.css';
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Recaptcha from 'react-recaptcha'

const Contact = ({data}) => {

  const { lang } = useContext(LangContext);
  const { text } = useContext(TextContext);

  const [state, setState] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });
  
  const [checked, setChecked] = useState(false);

  const [isloading, setIsloading] = useState(false);

  const { name, email, phone, message } = state;

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.name]: e.target.value
    })
  }

    
  // render on captcha load
  const handleCaptchaLoad = event =>  {
    return
  }

  // load on callback verify
  const verifyCallback = () => {
    setChecked(true);
  } 

  const handleSubmit = (e) => {
    e.preventDefault();
    if(!checked){
      store.addNotification({
        title: 'Oops',
        message: 'You have to check Recaptcha!',
        type: 'danger',                         // 'default', 'success', 'info', 'warning'
        container: 'top-right',                // where to position the notifications
        animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
        animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
        dismiss: {
          duration: 4000 
        }
      })
    }
    else{
      setIsloading(true);
      let jsonData = JSON.stringify(state);
      axios.post(`https://dashboard.almootamayezoon.com/index.php/examples/addContact`, jsonData).then(()=> {
        setState({name:'', email:'',phone:'',message:''});
        store.addNotification({
          title: 'Message Sent Successfully',
          message: 'Thanks',
          type: 'warning',                         // 'default', 'success', 'info', 'warning'
          container: 'top-right',                // where to position the notifications
          animationIn: ["animated", "fadeIn"],     // animate.css classes that's applied
          animationOut: ["animated", "fadeOut"],   // animate.css classes that's applied
          dismiss: {
            duration: 4000 
          }
        })
        setIsloading(false);
        setChecked(false);
      })
    }
  }

  const fetch = (key) => {
    return text.length === 0 ? '' : lang==='en'? text.find(el => el.lang_key === key).en_value : text.find(el => el.lang_key === key).ar_value;
  }

  return (
    <Layout>
      <SEO title="Contact Us"/>
      <div className="arrowDown2 d-none d-lg-block">
        <img src={arrowDown} alt="arrow" width="100%" />
      </div>
      <div className="contact">
        <p className="text-center header yellow text-uppercase mb-0"><FormattedMessage id="getInTouch" /></p>
        <div className="row justify-content-center formRow">
          <div className="col2 col-12 col-lg-5 mt-5 order-lg-2">
            <p className="header1 brown lineH text-uppercase"><FormattedMessage id="have" /></p>
            <p className="body brown mt-2"><FormattedMessage id="weAreAlways" /></p>
            {/* <img src={formImg} alt="contactFormImg" width="100%"/> */}
            <Img fluid={data.formImg.childImageSharp.fluid} alt="contactFormImg" />
          </div>
          <div className="col1 col-12 col-lg-5 pt-3">
            { isloading && <Loader />}
            { !isloading &&
              <form className="form" onSubmit={handleSubmit}>
                <input type="text" className="form-control body" name="name" placeholder={lang==='en'? "Name":"الاسم"} required autoComplete="off"  value={name} onChange={handleChange} />
                <input type="email" className="form-control body" name="email" placeholder={lang==='en'?"Email":"البريد الالكتروني"} required autoComplete="off" value={email} onChange={handleChange} />
                <input type="tel" className="form-control body"name="phone" placeholder={lang==='en'?"Phone Number":"رقم الهاتف"} pattern="^[0-9]*$" required autoComplete="off" value={phone} onChange={handleChange} />
                <textarea type="text" rows="2" name="message" className="form-control body" placeholder={lang==='en'?"Message":"الرسالة"}  value={message} onChange={handleChange} />
                <div className="mt-4">
                  <Recaptcha
                    sitekey="6LfPwtYUAAAAAHMp-b2MAh7Fu0PpIVLUVQaVvcpo"
                    render="explicit"
                    verifyCallback={verifyCallback}
                    onloadCallback={handleCaptchaLoad}
                  />
                </div>
                <button className="primaryBtn header2 float-right mt-5" type="submit"><FormattedMessage id="send" /></button>
              </form>
            }
          </div>
        </div>
        <div className="spacer"></div>
        <p className="text-center header yellow text-uppercase"><FormattedMessage id="giveUs" /></p>
        <div className="row justify-content-center">
          <div className="col-10 col-sm-6 col-lg-4">
            <div className="row justify-content-center">
              <div className="col-4">
                <p className="body grey"><FormattedMessage id="mobile" /></p>
              </div>
              <div className="col-8 col-sm-7">
                <p style={{direction:"ltr"}} className="body grey mb-0">{fetch('mobileNumber')}</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-4">
                <p className="body grey"><FormattedMessage id="phone" /></p>
              </div>
              <div className="col-8 col-sm-7">
                <p style={{direction:"ltr"}} className="body grey">{fetch('phoneNumber')}</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-4">
                <p className="body grey"><FormattedMessage id="fax" /></p>
              </div>
              <div className="col-8 col-sm-7">
                <p style={{direction:"ltr"}} className="body grey">{fetch('faxNumber')}</p>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="col-4">
                <p className="body grey"><FormattedMessage id="email" /></p>
              </div>
              <div className="col-8 col-sm-7">
                <a href="mailto:almootamayzoon@gmail.com"><p style={{direction:"ltr"}} className="body grey link">{fetch('emailAddress')}</p></a>
              </div>
            </div>
          </div>
          <div className="col-8 col-sm-4 col-lg-3 px-0 px-sm-3 mt-3 mt-sm-0">
            <p className="body grey mb-0">{fetch('address1')}</p>
            <p className="body grey mb-0">{fetch('address2')}</p>
            <p className="body grey mb-0">{fetch('address3')}</p>
            <p className="body grey mb-0">{fetch('address4')}</p>
          </div>
        </div>
        <div className="spacer2"></div>
        <div className="location row justify-content-center">
          <div className="col-12 col-sm-11 col-md-9 col-lg-7 background">
            <div className="map-responsive">
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3457.5527454481007!2d30.866901615112514!3d29.934775681922357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1458ff04c9974b8f%3A0xc4fca265db95fd5f!2z2KfZhNmF2KrZhdmK2LLZiNmGINmE2YTYtdmG2KfYudipINmI2KfZhNiq2KzYp9ix2Kk!5e0!3m2!1sen!2seg!4v1580664518053!5m2!1sen!2seg" title="Google Maps" width="100%" height="100%" frameBorder="0" style={{border:0}} allowFullScreen></iframe>
            </div>
          </div>
        </div>
        <div className="spacer2"></div>
      </div>
    </Layout>
  )
}

export default Contact;

export const fluidImage = graphql`
  fragment fluidImage on File {
    childImageSharp {
      fluid(maxWidth: 2000, quality: 75) {
        ...GatsbyImageSharpFluid_noBase64
      }
    }
  }
`;

export const pageQuery = graphql`
  query {
    formImg: file(relativePath: { eq: "contact/ContactUs_Art.png" }) {
      ...fluidImage
    }
  }
`;